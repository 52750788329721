<script lang="ts">
    import type { LayoutData } from './$types';
    import { goto } from '$app/navigation';
    import { alert, transformUrl } from '$lib/utils';
    import { browser } from '$app/environment';

    export let data: LayoutData;

    if (!data.session) {
        if (browser) {
            alert('로그인이 필요합니다.');
            goto(transformUrl('/sign-in'), { replaceState: true });
        }
    }
</script>

<slot />
